// 引入配置文件
const defaultSettings = require('@/settings.js')

// 接口地址
const url = defaultSettings.hostName;

const api = {
	// 用户登录
	Login: {
		url: url + 'login',
		description: '用户登陆',
		type: 'POST'
	},
	// 数据类别新增
	sysDataAdd: {
		url: url + 'bsi/sysDicCode/add',
		description: '数据类别新增',
		type: 'POST'
	},
	// 数据类别修改
	sysDataUpdate: {
		url: url + 'bsi/sysDicCode/update',
		description: '数据类别修改',
		type: 'POST'
	},
	// 数据类别分页查询
	sysDataPageList: {
		url: url + 'bsi/sysDicCode/pageList',
		description: '数据类别分页查询',
		type: 'POST'
	},
	// 数据类别新增
	sysDataDetailAdd: {
		url: url + 'bsi/sysDicData/add',
		description: '数据类别新增',
		type: 'POST'
	},
	// 数据类别修改
	sysDataDetailUpdate: {
		url: url + 'bsi/sysDicData/update',
		description: '数据类别修改',
		type: 'POST'
	},
	// 数据类别删除
	sysDataDetailDelete: {
		url: url + 'bsi/sysDicData/delete',
		description: '数据类别删除',
		type: 'POST'
	},
	// 数据类别-数据分页查询
	sysDataDetailPageList: {
		url: url + 'bsi/sysDicData/pageList',
		description: '数据类别-数据分页查询',
		type: 'POST'
	},
	// 数据类别-数据分页查询
	sysDicCodeList: {
		url: url + 'bsi/sysDicData/list',
		description: '数据类别-数据分页查询',
		type: 'POST'
	},
	// 获取基础数据(权限控制版)
	getListByCodesPerm: {
		url: url + 'bsi/sysDicData/listByCodesPerm',
		description: '获取基础数据(权限控制版)',
		type: 'POST'
	},
	// 机种机型管理分页查询
	sysModelPageList: {
		url: url + 'bsi/sysDeviceModel/pageList',
		description: '机种机型管理分页查询',
		type: 'POST'
	},
	// 机种机型管理新增
	sysModelAdd: {
		url: url + 'bsi/sysDeviceModel/add',
		description: '机种机型管理新增',
		type: 'POST'
	},
	// 机种机型管理修改
	sysModelUpdate: {
		url: url + 'bsi/sysDeviceModel/update',
		description: '机种机型管理修改',
		type: 'POST'
	},
	// 机种机型管理修改
	sysModelDelete: {
		url: url + 'bsi/sysDeviceModel/delete',
		description: '机种机型管理修改',
		type: 'POST'
	},
	// 机种机型数据获取
	sysModelDevice: {
		url: url + 'bsi/sysDeviceModel/getModelDevice',
		description: '机种机型数据获取',
		type: 'POST'
	},
	// 机种机型数据获取
	sysModelDeviceAll: {
		url: url + 'bsi/sysDeviceModel/getModelDeviceAll',
		description: '机种机型数据获取',
		type: 'POST'
	},
	// 角色列表查询
	sysRoleList: {
		url: url + 'bsi/sysRole/page',
		description: '角色列表查询',
		type: 'POST'
	},
	// 角色新增
	sysRoleAdd: {
		url: url + 'bsi/sysRole/add',
		description: '角色新增',
		type: 'POST'
	},
	// 角色修改
	sysRoleUpdate: {
		url: url + 'bsi/sysRole/update',
		description: '角色修改',
		type: 'POST'
	},
	// 角色删除
	sysRoleDelete: {
		url: url + 'bsi/sysRole/delete',
		description: '角色删除',
		type: 'POST'
	},
	// 权限树
	sysRoleTree: {
		url: url + 'bsi/sysMenuButton/tree',
		description: '权限树',
		type: 'POST'
	},
	// 分配权限
	sysRoleConfig: {
		url: url + 'bsi/sysRole/updateRolePerms',
		description: '分配权限',
		type: 'POST'
	},
	// 用户列表查询
	sysUserList: {
		url: url + 'bsi/sysUser/page',
		description: '用户列表查询',
		type: 'POST'
	},
	// 全部用户
	sysUserAll: {
		url: url + 'bsi/sysUser/all',
		description: '全部用户',
		type: 'POST'
	},
	// 新增用户
	sysUserAdd: {
		url: url + 'bsi/sysUser/add',
		description: '新增用户',
		type: 'POST'
	},
	// 编辑用户
	sysUserUpdate: {
		url: url + 'bsi/sysUser/update',
		description: '编辑用户',
		type: 'POST'
	},
	// 删除用户
	sysUserDelete: {
		url: url + 'bsi/sysUser/delete',
		description: '删除用户用户',
		type: 'POST'
	},
	// 省市区列表查询
	sysAreaPageList: {
		url: url + 'bsi/sysAreaRange/pageList',
		description: '省市区列表查询',
		type: 'POST'
	},
	// 省市区新增
	sysAreaAdd: {
		url: url + 'bsi/sysAreaRange/add',
		description: '省市区新增',
		type: 'POST'
	},
	// 省市区修改
	sysAreaUpdate: {
		url: url + 'bsi/sysAreaRange/update',
		description: '省市区修改',
		type: 'POST'
	},
	// 省市区修改
	sysAreaDelete: {
		url: url + 'bsi/sysAreaRange/delete',
		description: '省市区修改',
		type: 'POST'
	},
	// 机型名称维护查询
	sysDataDevicePageList: {
		url: url + 'bsi/dataDevice/pageList',
		description: '机型名称维护查询',
		type: 'POST'
	},
	// 机型名称维护查询
	sysGetDataDevice: {
		url: url + 'bsi/sysDeviceModel/getModelDevice',
		description: '机型名称维护查询',
		type: 'POST'
	},
	// 机型名称新增
	sysDataDeviceAdd: {
		url: url + 'bsi/dataDevice/add',
		description: '机型名称新增',
		type: 'POST'
	},
	// 机型名称修改
	sysDataDeviceUpdate: {
		url: url + 'bsi/dataDevice/update',
		description: '机型名称修改',
		type: 'POST'
	},
	// 机型名称修改
	sysDataDeviceDelete: {
		url: url + 'bsi/dataDevice/delete',
		description: '机型名称修改',
		type: 'POST'
	},
	// 公告新增
	dataNoticeAdd: {
		url: url + 'bsi/dataNotice/add',
		description: '公告新增',
		type: 'POST'
	},
	// 公告修改
	dataNoticeUpdate: {
		url: url + 'bsi/dataNotice/update',
		description: '公告修改',
		type: 'POST'
	},
	// 公告删除
	dataNoticeDelete: {
		url: url + 'bsi/dataNotice/delete',
		description: '公告删除',
		type: 'POST'
	},
	// 公告发布
	dataNoticeUpdateState: {
		url: url + 'bsi/dataNotice/updateNotice',
		description: '公告发布',
		type: 'POST'
	},
	// 公告撤销
	dataNoticeCancelPublish: {
		url: url + 'bsi/dataNotice/cancelPublish',
		description: '公告撤销',
		type: 'POST'
	},
	// 公告详情
	dataNoticeInfo: {
		url: url + 'bsi/dataNotice/info',
		description: '公告详情',
		type: 'GET'
	},
	// 公告分页查询
	dataNoticePageList: {
		url: url + 'bsi/dataNotice/pageList',
		description: '公告分页查询',
		type: 'POST'
	},
	// 公告导出
	dataNoticeExport: {
		url: url + 'bsi/dataNotice/export',
		description: '公告导出',
		type: 'POST'
	},
	// 图书馆新增
	dataLibraryAdd: {
		url: url + 'bsi/dataLibrary/addList',
		description: '图书馆新增',
		type: 'POST'
	},
	// 图书馆修改
	dataLibraryUpdate: {
		url: url + 'bsi/dataLibrary/update',
		description: '图书馆修改',
		type: 'POST'
	},
	// 图书馆删除
	dataLibraryDelete: {
		url: url + 'bsi/dataLibrary/delete',
		description: '图书馆删除',
		type: 'POST'
	},
	// 图书馆分页查询
	dataLibraryPageList: {
		url: url + 'bsi/dataLibrary/page',
		description: '图书馆分页查询',
		type: 'GET'
	},
	// 图书馆详情
	dataLibraryInfo: {
		url: url + 'bsi/dataLibrary/info',
		description: '图书馆详情',
		type: 'POST'
	},
	// 图书馆资料下载
	dataLibraryDownload: {
		url: url + 'bsi/dataLibrary/download',
		description: '图书馆资料下载',
		type: 'GET'
	},
	// 上传文件
	sysFileUpload: {
		url: url + 'bsi/sysAttachFile/addFile',
		description: '上传文件',
		type: 'POST'
	},
	// 添加文件记录
	sysFileAdd: {
		url: url + 'bsi/sysAttachFile/add',
		description: '添加文件记录',
		type: 'POST'
	},
	// 重置密码
	sysUserResetPassword: {
		url: url + 'bsi/sysUser/resetPass',
		description: '重置密码',
		type: 'POST'
	},
	// 获取用户编码
	sysGetUserCode: {
		url: url + 'bsi/sysUser/getAvailableCode',
		description: '获取用户编码',
		type: 'POST'
	},
	// 修改用户信息
	sysEditUserInfo: {
		url: url + 'bsi/sysUser/editProfile',
		description: '修改用户信息',
		type: 'POST'
	},
	// getFaqUrl
	getFaqUrl: {
		url: url + 'sys/workbench/getFaqUrl',
		description: 'getFaqUrl',
		type: 'POST'
	},
	// 获取经销商数据
	getDealerList: {
		url: url + 'bsi/sysAgent/pageMapList',
		description: '获取经销商数据',
		type: 'POST'
	},
	// 获取经销商数据
	getDealerLists: {
		url: url + 'bsi/sysAgent/selector',
		description: '获取经销商数据',
		type: 'POST'
	},
	// 获取所属公司数据
	getSelectorAgentLists: {
		url: url + 'bsi/sysAgent/selectorAgent',
		description: '获取所属公司数据',
		type: 'POST'
	},
	// 获取经销商数据
	getAgentAll: {
		url: url + 'bsi/sysAgent/list',
		description: '获取经销商数据',
		type: 'POST'
	},
	// 获取经销商新增数据
	getListByCodes: {
		url: url + 'bsi/sysDicData/listByCodes',
		description: '获取经销商新增数据',
		type: 'POST'
	},
	// 获取经销商新增数据 无权限控制
	getListAllByCodes: {
		url: url + 'bsi/sysDicData/listAllByCodes',
		description: '获取经销商新增数据',
		type: 'POST'
	},
	// 经销商新增
	sysAgentAdd: {
		url: url + 'bsi/sysAgent/add',
		description: '获取经销商新增数据',
		type: 'POST'
	},
	// 经销商编辑
	sysAgentUpdate: {
		url: url + 'bsi/sysAgent/update',
		description: '获取经销商新增数据',
		type: 'POST'
	},
	// 经销商编辑
	sysAgentDelete: {
		url: url + 'bsi/sysAgent/delete',
		description: '获取经销商新增数据',
		type: 'POST'
	},
	// 参数配置查询
	sysBaseConfigList: {
		url: url + 'bsi/sysBaseCfg/list',
		description: '参数配置查询',
		type: 'POST'
	},
	// 参数配置查询(map数据格式)
	sysBaseConfigListMap: {
		url: url + 'bsi/sysBaseCfg/listMap',
		description: '参数配置查询',
		type: 'POST'
	},
	// 参数配置修改
	sysBaseConfigUpdate: {
		url: url + 'bsi/sysBaseCfg/update',
		description: '参数配置修改',
		type: 'POST'
	},
	// 获取通讯组树结构
	sysDepartTree: {
		url: url + 'bsi/sysDept/tree',
		description: '获取通讯组树结构',
		type: 'GET'
	},
	// 获取通讯组下拉树结构
	sysDepartSelector: {
		url: url + 'bsi/sysDept/selector',
		description: '获取通讯组下拉树结构',
		type: 'GET'
	},
	// 获取通讯组详情
	sysDepatInfo: {
		url: url + 'bsi/sysDept/info',
		description: '获取通讯组详情',
		type: 'GET'
	},
	// 获取通讯组新增
	sysDepatAdd: {
		url: url + 'bsi/sysDept/add',
		description: '获取通讯组新增',
		type: 'POST'
	},
	// 获取通讯组编辑
	sysDepatUpdate: {
		url: url + 'bsi/sysDept/update',
		description: '获取通讯组编辑',
		type: 'POST'
	},
	// 获取通讯组删除
	sysDepatDelete: {
		url: url + 'bsi/sysDept/delete',
		description: '获取通讯组删除',
		type: 'POST'
	},
	// 获取通讯组用户
	sysDepatUserList: {
		url: url + 'bsi/sysUser/getDeptUserList',
		description: '获取通讯组用户',
		type: 'POST'
	},
	// 通讯组新增用户
	sysDepatAddUser: {
		url: url + 'bsi/sysDept/addUser',
		description: '通讯组新增用户',
		type: 'POST'
	},
	// 通讯组删除用户
	sysDepatDeleteUser: {
		url: url + 'bsi/sysDept/rmUser',
		description: '通讯组删除用户',
		type: 'POST'
	},
	// 通信箱新增
	dataSubjectAdd: {
		url: url + 'bsi/dataSubject/add',
		description: '新增通信箱数据',
		type: 'POST'
	},
	// 通信箱分页数据
	dataSubjectPageList: {
		url: url + 'bsi/dataSubject/pageList',
		description: '通信箱分页查询',
		type: 'POST'
	},
	// 通信箱导出
	dataSubjectExport: {
		url: url + 'bsi/dataSubject/export',
		description: '通信箱导出',
		type: 'POST'
	},
	// 通信箱导出
	// 通信箱编辑
	dataSubjectUpdate: {
		url: url + 'bsi/dataSubject/update',
		description: '通信箱编辑',
		type: 'POST'
	},
	// 通信箱发布
	dataSubjectPub: {
		url: url + 'bsi/dataSubject/pub',
		description: '通信箱发布',
		type: 'POST'
	},
	// 通信箱删除
	dataSubjectDelete: {
		url: url + 'bsi/dataSubject/delete',
		description: '通信箱删除',
		type: 'POST'
	},
	// 通信箱更新状态
	dataSubjectUpdateStatus: {
		url: url + 'bsi/dataSubject/updateStatus',
		description: '通信箱更新状态',
		type: 'POST'
	},
	// 通信箱详情
	dataSubjectInfo: {
		url: url + 'bsi/dataSubject/info',
		description: '通信箱详情',
		type: 'POST'
	},
	// 通信箱富文本内容
	dataSubjectRichText: {
		url: url + 'bsi/dataSubject/cInfo',
		description: '通信箱富文本',
		type: 'POST'
	},
	// 通信箱关闭
	dataSubjectClose: {
		url: url + 'bsi/dataSubject/close',
		description: '通信箱关闭',
		type: 'POST'
	},
	// 通信箱转单
	dataSubjectTransfer: {
		url: url + 'bsi/dataSubject/transfer',
		description: '通信箱转单',
		type: 'POST'
	},
    // 报告Rcl
    dataSubjectUpdateRcl: {
        url: url + 'bsi/dataSubject/updateRcl',
        description: '通信箱报告Rcl',
        type: 'POST'
    },
	// 接收人信息
	dataSubjectReceiveList: {
		url: url + 'bsi/dataSubjectReceive/list',
		description: '接收人信息',
		type: 'POST'
	},
	// 接收人信息
	dataSubjectReceiveAdd: {
		url: url + 'bsi/dataSubjectReceive/add',
		description: '通信箱接受',
		type: 'POST'
	},
	// 通信箱回复
	dataSubjectReplyAdd: {
		url: url + 'bsi/dataSubjectReply/add',
		description: '通信箱回复',
		type: 'POST'
	},
	// 通信箱回复删除
	dataSubjectReplyDelete: {
		url: url + 'bsi/dataSubjectReply/delete',
		description: '通信箱回复删除',
		type: 'POST'
	},
	// 通信箱回复数据查询
	dataSubjectReplyList: {
		url: url + 'bsi/dataSubjectReply/list',
		description: '通信箱回复数据查询',
		type: 'POST'
	},
	// 获取某个角色权限结构
	sysGetRoleList: {
		url: url + 'bsi/sysMenuButton/rolePermTree',
		description: '获取某个角色权限结构',
		type: 'GET'
	},
	// 文件下载
	dataFileDownload: {
		url: url + 'bsi/common/download',
		description: '文件下载',
		type: 'GET'
	},
	// 激活用户
	sysUserActive: {
		url: url + 'bsi/sysUser/active',
		description: '激活用户',
		type: 'POST'
	},
	// 失效用户
	sysUserInvalid: {
		url: url + 'bsi/sysUser/invalid',
		description: '失效用户',
		type: 'POST'
	},
	// 用户导出
	sysUserDownload: {
		url: url + 'bsi/sysUser/export',
		description: '用户导出',
		type: 'GET'
	},
	// 用户导入
	sysUserUpload: {
		url: url + 'bsi/sysUser/import',
		description: '用户导入',
		type: 'POST'
	},
	// 忘记密码
	forgetPass: {
		url: url + 'forgetPass',
		description: '忘记密码',
		type: 'POST'
	},
	// 忘记密码
	resetPass: {
		url: url + 'resetPass',
		description: '忘记密码',
		type: 'POST'
	},
	// 获取当前通讯组可以新增用户的列表
	getAllDepartUserList: {
		url: url + 'bsi/sysUser/all',
		description: '获取当前通讯组可以新增用户的列表',
		type: 'POST'
	},
	// 首页数据
	getHomeList: {
		url: url + 'sys/workbench/index',
		description: '首页数据',
		type: 'POST'
	},
	// 日志导出
	exportLog: {
		url: url + 'bsi/sysAccessLog/export',
		description: '日志导出',
		type: 'POST'
	},
	// 机型分类二级
	getModelDeviceSim: {
		url: url + 'bsi/sysDeviceModel/getModelDeviceSim',
		description: '机型分类二级',
		type: 'POST'
	},
	// 签约类型新增
	addContractRel: {
		url: url + 'bsi/dataContractRel/add',
		description: '签约类型新增',
		type: 'POST'
	},
	// 签约类型编辑
	updateContractRel: {
		url: url + 'bsi/dataContractRel/update',
		description: '签约类型编辑',
		type: 'POST'
	},
	// 签约类型删除
	deleteContractRel: {
		url: url + 'bsi/dataContractRel/delete',
		description: '签约类型删除',
		type: 'POST'
	},
	// 签约类型列表
	listContractRel: {
		url: url + 'bsi/dataContractRel/pageList',
		description: '签约类型列表',
		type: 'POST'
	},
	// 统计固件
	clickFirmware: {
		url: url + 'sys/workbench/clickFirmware',
		description: '统计固件',
		type: 'POST'
	},
	// 获取经销商id或学员id
	getAutoCodeId: {
		url: url + 'bsi/sysBaseCfg/getAutoCodeId',
		description: '获取经销商id或学员id',
		type: 'POST'
	},
	// 经销商导出
	sysAgentExport: {
		url: url + 'bsi/sysAgent/export',
		description: '经销商导出',
		type: 'POST'
	},
	// 经销商激活
	sysAgentActive: {
		url: url + 'bsi/sysAgent/active',
		description: '经销商激活',
		type: 'POST'
	},
	// 经销商失效
	sysAgentInvalid: {
		url: url + 'bsi/sysAgent/invalid',
		description: '经销商失效',
		type: 'POST'
	},
	// 经销商导入
	sysAgentImport: {
		url: url + 'bsi/sysAgent/import',
		description: '经销商导入',
		type: 'POST'
	},
	// 公司导入
	sysCompanyImport: {
		url: url + 'bsi/sysAgent/companyImport',
		description: '公司导入',
		type: 'POST'
	},
	// 用户数据
	getUserInfo: {
		url: url + 'bsi/sysUser/info',
		description: '用户数据',
		type: 'GET'
	},
	// 用户数据
	allExcludeDept: {
		url: url + 'bsi/sysUser/allExcludeDept',
		description: '用户数据',
		type: 'GET'
	},
	// 用户数据
	getInfoByCode: {
		url: url + 'bsi/sysBaseCfg/getInfoByCode',
		description: '用户数据',
		type: 'GET'
	},
	// 用户导出
	sysRolDownload: {
		url: url + 'bsi/sysRole/export',
		description: '用户导出',
		type: 'GET'
	},
	// 获取上级区域代理商
	listByCondition: {
		url: url + 'bsi/sysAgent/listByCondition',
		description: '获取上级区域代理商',
		type: 'POST'
	},
	// 获取上级区域代理商
	getAreaAgent: {
		url: url + 'bsi/sysAgent/getAreaAgent',
		description: '获取上级区域代理商',
		type: 'POST'
	},
	// 课程列表
	getCourseList: {
		url: url + 'course/courseInfo/pageList',
		description: '课程列表',
		type: 'POST'
	},
	// 课程新增
	addCourse: {
		url: url + 'course/courseInfo/add',
		description: '课程新增',
		type: 'POST'
	},
	// 课程发布撤销
	coursePub: {
		url: url + 'course/courseInfo/pub',
		description: '课程发布撤销',
		type: 'POST'
	},
	// 课程删除
	deleteCourse: {
		url: url + 'course/courseInfo/del',
		description: '课程删除',
		type: 'POST'
	},
	// 课程信息
	courseInfo: {
		url: url + 'course/courseInfo/info',
		description: '课程信息',
		type: 'POST'
	},
	// 课程信息
	dayCourseInfo: {
		url: url + 'course/courseInfo/dayCourseInfo',
		description: '课程信息',
		type: 'POST'
	},
	// 课程编辑
	courseUpdate: {
		url: url + 'course/courseInfo/update',
		description: '课程编辑',
		type: 'POST'
	},
	// 学员列表
	studentList: {
		url: url + 'course/courseStudent/page',
		description: '学员列表',
		type: 'GET'
	},
	// 教师列表
	teacherList: {
		url: url + 'course/courseTeacher/page',
		description: '教师列表',
		type: 'GET'
	},
	// 会场列表
	roomList: {
		url: url + 'course/courseRoom/page',
		description: '会场列表',
		type: 'GET'
	},
	// 试题列表
	courseQuestionList: {
		url: url + 'course/courseQues/pageList',
		description: '试题列表',
		type: 'POST'
	},
	// 新增试题
	addQuestion: {
		url: url + 'course/courseQues/add',
		description: '新增试题',
		type: 'POST'
	},
	// 编辑试题
	updateQuestion: {
		url: url + 'course/courseQues/update',
		description: '编辑试题',
		type: 'POST'
	},
	// 删除试题
	deleteQuestion: {
		url: url + 'course/courseQues/del',
		description: '删除试题',
		type: 'POST'
	},
	// 新增会场
	addRoom: {
		url: url + 'course/courseRoom/add',
		description: '新增会场',
		type: 'POST'
	},
	// 编辑会场
	updateRoom: {
		url: url + 'course/courseRoom/update',
		description: '编辑会场',
		type: 'POST'
	},
	// 删除会场
	deleteRoom: {
		url: url + 'course/courseRoom/delete',
		description: '删除会场',
		type: 'POST'
	},
	// 获取教师列表
	getTeacherList: {
		url: url + 'course/courseTeacher/page',
		description: '获取教师列表',
		type: 'GET'
	},
	// 获取教师列表
	getAllTeacherList: {
		url: url + 'course/courseTeacher/alternatePage',
		description: '获取教师列表',
		type: 'GET'
	},
	// 新增教师
	addTeacher: {
		url: url + 'course/courseTeacher/add',
		description: '新增教师',
		type: 'POST'
	},
	// 删除教师
	deleteTeacher: {
		url: url + 'course/courseTeacher/delete',
		description: '删除教师',
		type: 'POST'
	},
	// 已发布课程
	publishCourseList: {
		url: url + 'course/courseInfo/list',
		description: '已发布课程',
		type: 'POST'
	},
	// 新增集合培训
	addTrainOffline: {
		url: url + 'course/courseTrainOffline/add',
		description: '新增集合培训',
		type: 'POST'
	},
	// 编辑集合培训
	updateTrainOffline: {
		url: url + 'course/courseTrainOffline/update',
		description: '编辑集合培训',
		type: 'POST'
	},
	// 删除集合培训
	deleteTrainOffline: {
		url: url + 'course/courseTrainOffline/del',
		description: '删除集合培训',
		type: 'POST'
	},
	// 集合培训信息
	trainOfflineInfo: {
		url: url + 'course/courseTrainOffline/info',
		description: '集合培训信息',
		type: 'POST'
	},
	// 远程培训信息
	trainOnlineInfo: {
		url: url + 'course/courseTrainOnline/info',
		description: '远程培训信息',
		type: 'POST'
	},
	// 发布撤销集合培训
	publishTrainOffline: {
		url: url + 'course/courseTrainOffline/pub',
		description: '发布撤销集合培训',
		type: 'POST'
	},
	// 获取教育编码
	getEduCode: {
		url: url + 'course/courseTrainOffline/planCode',
		description: '获取教育编码',
		type: 'POST'
	},
	// 列表集合培训
	getTrainOfflineList: {
		url: url + 'course/courseTrainOffline/pageList',
		description: '列表集合培训',
		type: 'POST'
	},
	// 添加报名人员
	signOffline: {
		url: url + 'course/courseTrainResult/addOffLineUser',
		description: '添加报名人员',
		type: 'POST'
	},
	// 获取考试成绩列表
	getResultOfflineList: {
		url: url + 'course/courseTrainResult/pageList',
		description: '获取考试成绩列表',
		type: 'POST'
	},
	// 修改成绩
	updateOfflineResult: {
		url: url + 'course/courseTrainResult/update',
		description: '修改成绩',
		type: 'POST'
	},
	// 删除成绩
	deleteOfflineResult: {
		url: url + 'course/courseTrainResult/del',
		description: '删除成绩',
		type: 'POST'
	},
	// 经销商分组列表
	dealerGroupList: {
		url: url + 'bsi/sysAgentGroup/all',
		description: '经销商分组列表',
		type: 'GET'
	},
	// 经销商分组新增
	dealerGroupAdd: {
		url: url + 'bsi/sysAgentGroup/add',
		description: '经销商分组列表',
		type: 'POST'
	},
	// 经销商分组更新
	dealerGroupUpdate: {
		url: url + 'bsi/sysAgentGroup/update',
		description: '经销商分组更新',
		type: 'POST'
	},
	// 经销商分组删除
	dealerGroupDelete: {
		url: url + 'bsi/sysAgentGroup/delete',
		description: '经销商分组删除',
		type: 'POST'
	},
	// 经销商分组添加经销商
	dealerGroupAppend: {
		url: url + 'bsi/sysAgentGroup/addMember',
		description: '经销商分组更新',
		type: 'POST'
	},
	// 经销商分组删除经销商
	dealerGroupRemove: {
		url: url + 'bsi/sysAgentGroup/rmMember',
		description: '经销商分组删除',
		type: 'POST'
	},
	// 远程培训列表
	getTrainOnlineList: {
		url: url + 'course/courseTrainOnline/pageList',
		description: '远程培训列表',
		type: 'POST'
	},
	// 远程培训结束时间
	getTrainOnlineEndTime: {
		url: url + 'course/courseTrainOnline/getEndTime',
		description: '远程培训结束时间',
		type: 'POST'
	},
	// 远程培训新增
	addTrainOnline: {
		url: url + 'course/courseTrainOnline/add',
		description: '远程培训新增',
		type: 'POST'
	},
	// 远程培训更新
	updateTrainOnline: {
		url: url + 'course/courseTrainOnline/update',
		description: '远程培训更新',
		type: 'POST'
	},
	// 远程培训删除
	deleteTrainOnline: {
		url: url + 'course/courseTrainOnline/del',
		description: '远程培训删除',
		type: 'POST'
	},
	// 考前学习目录
	getExamAttList: {
		url: url + 'course/courseQues/attaList',
		description: '考前学习目录',
		type: 'POST'
	},
	// 考前须知
	getExamBeforeInfo: {
		url: url + 'course/courseQues/examNotes',
		description: '考前须知',
		type: 'POST'
	},
	// 考题列表
	getExamList: {
		url: url + 'course/courseQues/quesList',
		description: '考题列表',
		type: 'POST'
	},
	// 考题开始
	startExam: {
		url: url + 'course/courseTrainResult/start',
		description: '考题开始',
		type: 'POST'
	},
	// 考题提交
	submitExam: {
		url: url + 'course/courseTrainResult/end',
		description: '考题提交',
		type: 'POST'
	},
	// 考题提交
	agreeExam: {
		url: url + 'course/courseTrainResult/agree',
		description: '考题提交',
		type: 'POST'
	},
	// 考试成绩
	getExamCourseList: {
		url: url + 'course/courseTrainOnline/testList',
		description: '考题考试成绩提交',
		type: 'POST'
	},
	// 考试成绩
	getExamScoreList: {
		url: url + 'course/courseTrainResult/scoreList',
		description: '考题考试成绩提交',
		type: 'POST'
	},
	// 获取履历地区树结构
	getHistoryAreaTree: {
		url: url + 'course/stuHis/getAreaTree',
		description: '获取履历地区树结构',
		type: 'POST'
	},
	// 获取履历地区树结构
	getHistoryCourseTree: {
		url: url + 'course/stuHis/getCourseTree',
		description: '获取履历地区树结构',
		type: 'POST'
	},
	// 获取履历地区树结构
	getHistoryAreaList: {
		url: url + 'course/stuHis/getAreaTreeData',
		description: '获取履历地区树结构',
		type: 'POST'
	},
	// 获取履历地区树结构
	getHistoryCourseList: {
		url: url + 'course/stuHis/getCourseTreeData',
		description: '获取履历地区树结构',
		type: 'POST'
	},
	// 试题导入
	questionImport: {
		url: url + 'course/courseQues/import',
		description: '试题导入',
		type: 'POST'
	},
	// 培训日程表列表
	getCourseTrainSchedule: {
		url: url + 'course/courseInfo/getCourseTrainSchedule',
		description: '培训日程表列表',
		type: 'POST'
	},
	// 教师列表
	getCourseTeacherList: {
		url: url + 'course/courseTeacher/list',
		description: '教师列表',
		type: 'POST'
	},
	// 通讯组导出
	sysDepDownload: {
		url: url + 'bsi/sysDept/export',
		description: '用户导出',
		type: 'GET'
	},
	// 通讯组导入
	sysDepUpload: {
		url: url + 'bsi/sysDept/import',
		description: '用户导入',
		type: 'POST'
	},
	// 学员导出
	studentDownload: {
		url: url + 'course/courseStudent/export',
		description: '学员导出',
		type: 'GET'
	},
	// 学员导入
	studentUpload: {
		url: url + 'course/courseStudent/import',
		description: '学员导入',
		type: 'POST'
	},
	// 教师导出
	teacherDownload: {
		url: url + 'course/courseTeacher/export',
		description: '教师导出',
		type: 'GET'
	},
	// 教师导入
	teacherUpload: {
		url: url + 'course/courseTeacher/import',
		description: '教师导入',
		type: 'POST'
	},
	// 履历导出
	historyAreaDownload: {
		url: url + 'course/stuHis/areaTreeDataExport',
		description: '履历导出',
		type: 'GET'
	},
	// 履历导出
	historyCourseDownload: {
		url: url + 'course/stuHis/courseTreeDataExport',
		description: '履历导出',
		type: 'GET'
	},
	// 集合导出
	courseTrainOfflineDownload: {
		url: url + 'course/courseTrainOffline/export',
		description: '集合导出',
		type: 'POST'
	},
	// 远程导出
	courseTrainOnlineDownload: {
		url: url + 'course/courseTrainOnline/export',
		description: '远程导出',
		type: 'POST'
	},
	// 远程培训发布
	courseTrainOnlinePub: {
		url: url + 'course/courseTrainOnline/pub',
		description: '远程培训发布',
		type: 'POST'
	},
	// 远程培训撤销
	courseTrainOnlineCancel: {
		url: url + 'course/courseTrainOnline/cancel',
		description: '远程培训撤销',
		type: 'POST'
	},
	// 获取经销商签约类型
	getContractTypeName: {
		url: url + 'bsi/sysAgent/getContractTypeName',
		description: '获取经销商签约类型',
		type: 'POST'
	},
	// 公司导出
	sysCompanyExport: {
		url: url + 'bsi/sysAgent/exportCompany',
		description: '公司导出',
		type: 'POST'
	},
	// 集合导入
	courseTrainOfflineUpload: {
		url: url + 'course/courseTrainOffline/import',
		description: '用户导入',
		type: 'POST'
	},
	// 课程导入
	courseInfoUpload: {
		url: url + 'course/courseInfo/import',
		description: '课程导入',
		type: 'POST'
	},
	// 课程导出
	courseInfoDownload: {
		url: url + 'course/courseInfo/export',
		description: '课程导出',
		type: 'POST'
	},
	// 补登成绩导入
	courseInfoUploadScore: {
		url: url + 'course/courseTrainOffline/importScore',
		description: '补登成绩导入',
		type: 'POST'
	},
	// 补登成绩导入
	courseInfoUploadScore1: {
		url: url + 'course/courseTrainOffline/importAppendScore',
		description: '补登成绩导入',
		type: 'POST'
	},
	// 课程成绩导入
	courseImportScore: {
		url: url + 'course/courseTrainOffline/importScore',
		description: '课程成绩导入',
		type: 'POST'
	},
	// 课程成绩导出
	courseExportScore: {
		url: url + 'course/courseTrainOffline/exportScore',
		description: '课程成绩导出',
		type: 'POST'
	},
	// 固件下载
	rch_download: {
		url: url + 'bsi/common/rch_download',
		description: '固件下载',
		type: 'GET'
	},
	// 机型名称导出
	dataDeviceExport: {
		url: url + 'bsi/dataDevice/export',
		description: '机型名称导出',
		type: 'POST'
	},
	// 机型名称导入
	dataDeviceImport: {
		url: url + 'bsi/dataDevice/import',
		description: '机型名称导入',
		type: 'POST'
	},
	// 经销商分组导出
	sysAgentGroupExport: {
		url: url + 'bsi/sysAgentGroup/export',
		description: '经销商分组导出',
		type: 'POST'
	},
	// 经销商分组导入
	sysAgentGroupImport: {
		url: url + 'bsi/sysAgentGroup/import',
		description: '经销商分组导入',
		type: 'POST'
	},
	// 获取下载key
	getDownloadKey: {
		url: url + 'bsi/common/get_download_key',
		description: '获取下载key',
		type: 'GET'
	},
	// 获取通信箱主信息
	getMailboxMainInfo: {
		url: url + 'bsi/dataSubject/cInfo',
		description: '获取通信箱主信息',
		type: 'POST'
	},
	// 获取所有学员
	getAllStudent: {
		url: url + 'course/courseStudent/selector',
		description: '获取所有学员',
		type: 'POST'
	},
	// 远程培训新增
	addTrainOnlines: {
		url: url + 'course/courseTrainOnline/addList',
		description: '远程培训新增',
		type: 'POST'
	},
	// 阅读章节查询
	readInfo: {
		url: url + 'course/courseTrainOnlineRead/readInfo',
		description: '阅读章节查询',
		type: 'POST'
	},
	// 阅读章节更新
	updateReadInfo: {
		url: url + 'course/courseTrainOnlineRead/updateReadInfo',
		description: '阅读章节更新',
		type: 'POST'
	},
	// 撤销集合培训
	courseTrainOfflineRevoke: {
		url: url + 'course/courseTrainOffline/revoke',
		description: '撤销集合培训',
		type: 'POST'
	},
	// 关闭集合培训
	courseTrainOfflineClose: {
		url: url + 'course/courseTrainOffline/close',
		description: '关闭集合培训',
		type: 'POST'
	},
	// 查看报名人员
	courseTrainResultViewMember: {
		url: url + 'course/courseTrainResult/viewMember',
		description: '查看报名人员',
		type: 'POST'
	},
	// 考试成绩-查看成绩
	courseTrainResultViewResult: {
		url: url + 'course/courseTrainResult/viewResult',
		description: '考试成绩-查看成绩',
		type: 'POST'
	},
	// 补登成绩-查看成绩
	courseTrainResultAddViewMember: {
		url: url + 'course/courseTrainResult/addViewResult',
		description: '补登成绩-查看成绩',
		type: 'POST'
	},
	// 课程编号管理-撤销课程
	courseInfoRevoke: {
		url: url + 'course/courseInfo/revoke',
		description: '课程编号管理-撤销课程',
		type: 'POST'
	},
	// 试题维护管理
	courseInfoMaintain: {
		url: url + 'course/courseInfo/maintain',
		description: '试题维护管理',
		type: 'POST'
	},
	// 公司管理列表
	pageMapCompanyList: {
		url: url + 'bsi/sysAgent/pageMapCompanyList',
		description: '公司管理列表',
		type: 'POST'
	},
	// 公司管理-新增
	addCompany: {
		url: url + 'bsi/sysAgent/addCompany',
		description: '公司管理-新增',
		type: 'POST'
	},
	// 公司管理-编辑
	updateCompany: {
		url: url + 'bsi/sysAgent/updateCompany',
		description: '公司管理-编辑',
		type: 'POST'
	},
	// 公司管理-变更
	updateAgent: {
		url: url + 'bsi/sysAgent/updateAgent',
		description: '公司管理-变更',
		type: 'POST'
	},
	// 公司管理-激活公司
	activeCompany: {
		url: url + 'bsi/sysAgent/activeCompany',
		description: '公司管理-激活公司',
		type: 'POST'
	},
	// 公司管理-失效公司
	invalidCompany: {
		url: url + 'bsi/sysAgent/invalidCompany',
		description: '公司管理-失效公司',
		type: 'POST'
	},
	// 学员管理-新增
	addStudent: {
		url: url + 'bsi/sysUser/addStudent',
		description: '学员管理-新增',
		type: 'POST'
	},
	// 学员管理-编辑
	updateStudent: {
		url: url + 'bsi/sysUser/updateStudent',
		description: '学员管理-编辑',
		type: 'POST'
	},
	// 学员管理-失效学员
	invalidStudent: {
		url: url + 'bsi/sysUser/invalidStudent',
		description: '学员管理-失效学员',
		type: 'POST'
	},
	// 学员管理-激活学员
	activeStudent: {
		url: url + 'bsi/sysUser/activeStudent',
		description: '学员管理-激活学员',
		type: 'POST'
	},
	// 培训履历-地区-远程
	getAreaTreeOnline: {
		url: url + 'course/stuHis/getAreaTreeOnline',
		description: '培训履历-地区-远程',
		type: 'POST'
	},
	// 培训履历-地区-集合
	getAreaTreeOffline: {
		url: url + 'course/stuHis/getAreaTreeOffline',
		description: '培训履历-地区-集合',
		type: 'POST'
	},
	// 培训履历-地区-远程
	getAreaTreeOnlineInvalid: {
		url: url + 'course/stuHis/getAreaTreeOnlineInvalid',
		description: '培训履历-地区-远程-失效',
		type: 'POST'
	},
	// 培训履历-地区-集合
	getAreaTreeOfflineInvalid: {
		url: url + 'course/stuHis/getAreaTreeOfflineInvalid',
		description: '培训履历-地区-集合-失效',
		type: 'POST'
	},
	// 培训履历-课程-远程
	getCourseTreeOnline: {
		url: url + 'course/stuHis/getCourseTreeOnline',
		description: '培训履历-地区-集合',
		type: 'POST'
	},
	// 培训履历-课程-集合
	getCourseTreeOffline: {
		url: url + 'course/stuHis/getCourseTreeOffline',
		description: '培训履历-地区-集合',
		type: 'POST'
	},
	// 培训履历-课程-远程-失效
	getCourseTreeOnlineInvalid: {
		url: url + 'course/stuHis/getCourseTreeOnlineInvalid',
		description: '培训履历-地区-集合-失效',
		type: 'POST'
	},
	// 培训履历-课程-集合-失效
	getCourseTreeOfflineInvalid: {
		url: url + 'course/stuHis/getCourseTreeOfflineInvalid',
		description: '培训履历-地区-集合-失效',
		type: 'POST'
	},
	// 培训履历-地区-远程-导出
	areaTreeDataExportOnline: {
		url: url + 'course/stuHis/areaTreeDataExportOnline',
		description: '培训履历-地区-远程-导出',
		type: 'POST'
	},
	// 培训履历-地区-集合-导出
	areaTreeDataExportOffline: {
		url: url + 'course/stuHis/areaTreeDataExportOffline',
		description: '培训履历-地区-集合-导出',
		type: 'POST'
	},
	// 培训履历-地区-远程-导出
	areaTreeDataExportOnlineInvalid: {
		url: url + 'course/stuHis/areaTreeDataExportOnlineInvalid',
		description: '培训履历-地区-远程-失效-导出',
		type: 'POST'
	},
	// 培训履历-地区-集合-导出
	areaTreeDataExportOfflineInvalid: {
		url: url + 'course/stuHis/areaTreeDataExportOfflineInvalid',
		description: '培训履历-地区-集合-失效-导出',
		type: 'POST'
	},
	// 培训履历-课程-远程-导出
	courseTreeDataExportOnline: {
		url: url + 'course/stuHis/courseTreeDataExportOnline',
		description: '培训履历-地区-集合-导出',
		type: 'POST'
	},
	// 培训履历-课程-集合-导出
	courseTreeDataExportOffline: {
		url: url + 'course/stuHis/courseTreeDataExportOffline',
		description: '培训履历-地区-集合-导出',
		type: 'POST'
	},
	// 培训履历-课程-远程-失效-导出
	courseTreeDataExportOnlineInvalid: {
		url: url + 'course/stuHis/courseTreeDataExportOnlineInvalid',
		description: '培训履历-地区-集合-失效-导出',
		type: 'POST'
	},
	// 培训履历-课程-集合-失效-导出
	courseTreeDataExportOfflineInvalid: {
		url: url + 'course/stuHis/courseTreeDataExportOfflineInvalid',
		description: '培训履历-地区-集合-失效-导出',
		type: 'POST'
	},
	// 经销商分组管理-列表
	pageMapAgentGroupList: {
		url: url + 'bsi/sysAgent/pageMapAgentGroupList',
		description: '经销商分组管理-列表',
		type: 'POST'
	},
	// 集合培训-集合报名
	registerList: {
		url: url + 'course/courseTrainOffline/registerList',
		description: '集合培训-集合报名',
		type: 'POST'
	},
	// 集合培训-考试成绩
	resultManageList: {
		url: url + 'course/courseTrainOffline/resultManageList',
		description: '集合培训-考试成绩',
		type: 'POST'
	},
	// 集合培训-补登成绩
	addResultList: {
		url: url + 'course/courseTrainOffline/addResultList',
		description: '集合培训-补登成绩',
		type: 'POST'
	},
	// 试题管理导出
	questionDownload: {
		url: url + 'course/courseQues/exportQues',
		description: '试题管理导出',
		type: 'POST'
	},
	// 清空通讯组成员
	clearMember: {
		url: url + 'bsi/sysDept/clearMember',
		description: '清空通讯组成员',
		type: 'POST'
	},
	// 视频分页列表
	dataVideoPageList: {
		url: url + 'bsi/dataVideo/pageList',
		description: '视频分页列表',
		type: 'POST'
	},
	// 视频置顶列表
	dataVideoTopList: {
		url: url + 'bsi/dataVideo/topList',
		description: '视频置顶列表',
		type: 'POST'
	},
	// 视频分组列表
	dataVideoGroupList: {
		url: url + 'bsi/dataVideo/searchGroup',
		description: '视频分组列表',
		type: 'POST'
	},
	// 视频详情
	dataVideoInfo: {
		url: url + 'bsi/dataVideo/info',
		description: '视频详情',
		type: 'POST'
	},
	// 视频新增
	dataVideoAdd: {
		url: url + 'bsi/dataVideo/add',
		description: '视频新增',
		type: 'POST'
	},
	// 视频修改
	dataVideoUpdate: {
		url: url + 'bsi/dataVideo/update',
		description: '视频修改',
		type: 'POST'
	},
	// 视频删除
	dataVideoDelete: {
		url: url + 'bsi/dataVideo/delete',
		description: '视频删除',
		type: 'POST'
	},
	// 视频发布
	dataVideoPublish: {
		url: url + 'bsi/dataVideo/publish',
		description: '视频发布',
		type: 'POST'
	},
	// 视频取消发布
	dataVideoCancel: {
		url: url + 'bsi/dataVideo/cancel',
		description: '视频取消发布',
		type: 'POST'
	},
	// 视频置顶
	dataVideoAddTop: {
		url: url + 'bsi/dataVideo/addTop',
		description: '视频置顶',
		type: 'POST'
	},
	// 视频取消置顶
	dataVideoRemoveTop: {
		url: url + 'bsi/dataVideo/removeTop',
		description: '视频取消置顶',
		type: 'POST'
	},
	// 更多视频
	dataVideoMore: {
		url: url + 'bsi/dataVideo/more',
		description: '更多视频',
		type: 'POST'
	},
	// 视频点击量
	dataVideoClick: {
		url: url + 'bsi/dataVideo/click',
		description: '增加视频点击量',
		type: 'POST'
	},
	// 视频导出
	dataVideoExport: {
		url: url + 'bsi/dataVideo/export',
		description: '视频导出',
		type: 'POST'
	},
	// 技术资料分页数据
	dataSubjectPageMaterialList: {
		url: url + 'bsi/dataSubject/pageMaterialList',
		description: '技术资料分页查询',
		type: 'POST'
	},
	// 技术资料增加点击量
	dataSubjectClick: {
		url: url + 'bsi/dataSubject/click',
		description: '技术资料增加点击量',
		type: 'POST'
	},
	// 获取OSS上传链接
	getOssPreUploadUrl: {
		url: url + 'bsi/common/getOssPreUploadUrl',
		description: '获取OSS上传链接',
		type: 'POST'
	},
	// 获取OSS上传参数
	getOssTemCredential: {
		url: url + 'bsi/common/getOssTemCredential',
		description: '获取OSS上传参数',
		type: 'GET'
	},
	// 修改密码
	editPass: {
		url: url + 'bsi/sysUser/editPass',
		description: '修改密码',
		type: 'POST'
	},
    // 索赔维修机型列表
    repairModelPageList: {
        url: url + 'claim/claimRepairDevice/pageList',
        description: '索赔维修机型列表',
        type: 'POST'
    },
    // 新增索赔维修机型
    repairModelAdd: {
        url: url + 'claim/claimRepairDevice/add',
        description: '新增索赔维修机型',
        type: 'POST'
    },
    // 编辑索赔维修机型
    repairModelUpdate: {
        url: url + 'claim/claimRepairDevice/update',
        description: '编辑索赔维修机型',
        type: 'POST'
    },
    // 删除索赔维修机型
    repairModelDelete: {
        url: url + 'claim/claimRepairDevice/del',
        description: '删除索赔维修机型',
        type: 'POST'
    },
    // 索赔申请列表
    repairRequestPageList: {
        url: url + 'claim/claimAuthRepairOrder/pageList',
        description: '索赔申请列表',
        type: 'POST'
    },
    // 新增索赔申请
    repairRequestAdd: {
        url: url + 'claim/claimAuthRepairOrder/add',
        description: '新增索赔申请',
        type: 'POST'
    },
    // 编辑索赔申请
    repairRequestUpdate: {
        url: url + 'claim/claimAuthRepairOrder/update',
        description: '编辑索赔申请',
        type: 'POST'
    },
    // 删除索赔申请
    repairRequestDelete: {
        url: url + 'claim/claimAuthRepairOrder/del',
        description: '删除索赔申请',
        type: 'POST'
    },
    // 获取索赔申请详情
    repairRequestInfo: {
        url: url + 'claim/claimAuthRepairOrder/info',
        description: '获取索赔申请详情',
        type: 'GET'
    },
	// 索赔管理-延保记录-新增
	repairExtendedRequestAdd: {
		url: url + 'claim/claimAuthRepairExtended/add',
		description: '新增加延保申请',
		type: 'POST'
	},
	// 索赔管理-延保记录-更新
	repairExtendedRequestUpdate: {
		url: url + 'claim/claimAuthRepairExtended/update',
		description: '更新延保记录',
		type: 'POST'
	},
	// 索赔管理-延保记录-列表
	repairExtendedRequestPageList: {
		url: url + 'claim/claimAuthRepairExtended/pageList',
		description: '获取延保记录列表',
		type: 'POST'
	},
	// 索赔管理-延保记录-删除
	repairExtendedRequestDel: {
		url: url + 'claim/claimAuthRepairExtended/del',
		description: '删除延保记录',
		type: 'POST'
	},
	// 索赔管理-送货地址-新增
	repairRepairAdvanceAdd: {
		url: url + 'claim/claimRepairAdvance/add',
		description: '新增送货地址',
		type: 'POST'
	},
	// 索赔管理-送货地址-列表
	repairRepairAdvancePageList: {
		url: url + 'claim/claimRepairAdvance/pageList',
		description: '获取送货地址列表',
		type: 'POST'
	},
	// 索赔管理-送货地址-更新
	repairRepairAdvanceUpdate: {
		url: url + 'claim/claimRepairAdvance/update',
		description: '更新送货地址',
		type: 'POST'
	},
	// 索赔管理-送货地址-删除
	repairRepairAdvanceDel: {
		url: url + 'claim/claimRepairAdvance/del',
		description: '删除送货地址',
		type: 'POST'
	},
	// 索赔管理-结算信息-新增
	repairRepairSettleAdd: {
		url: url + 'claim/claimRepairSettle/add',
		description: '新增结算信息',
		type: 'POST'
	},
	// 索赔管理-结算信息-列表
	repairRepairSettlePageList: {
		url: url + 'claim/claimRepairSettle/pageList',
		description: '获取结算信息列表',
		type: 'POST'
	},
	// 索赔管理-结算信息-更新
	repairRepairSettleUpdate: {
		url: url + 'claim/claimRepairSettle/update',
		description: '更新结算信息',
		type: 'POST'
	},
	// 索赔管理-结算信息-删除
	repairRepairSettleDel: {
		url: url + 'claim/claimRepairSettle/del',
		description: '删除结算信息',
		type: 'POST'
	},
	// 索赔管理-结算信息-金额
	repairRepairSumSettle: {
		url: url + 'claim/claimRepairSettle/sumSettle',
		description: '获取结算信息页面金额',
		type: 'POST'
	},
	// 索赔管理-结算信息-批量结算
	repairRepairBatchSettle: {
		url: url + 'claim/claimRepairSettle/updateBatchSettle',
		description: '批量结算',
		type: 'POST'
	},
	// 索赔管理-已换机机号-新增
	repairExchangeRequestAdd: {
		url: url + 'claim/claimRepairExchange/add',
		description: '新增已换机机号',
		type: 'POST'
	},
	// 索赔管理-已换机机号-更新
	repairExchangeRequestUpdate: {
		url: url + 'claim/claimRepairExchange/update',
		description: '更新已换机机号',
		type: 'POST'
	},
	// 索赔管理-已换机机号-列表
	repairExchangeRequestPageList: {
		url: url + 'claim/claimRepairExchange/pageList',
		description: '获取已换机机号列表',
		type: 'POST'
	},
	// 索赔管理-已换机机号-删除
	repairExchangeRequestDel: {
		url: url + 'claim/claimRepairExchange/del',
		description: '删除已换机机号',
		type: 'POST'
	},
    // 获取零件信息
    claimantCsmsInfo: {
        url: url + 'claim/claimAuthRepairOrder/csms',
        description: '获取零件信息',
        type: 'GET'
    },
    // 获取省市县三级数据
    getDataAddr: {
        url: url + 'bsi/dataAddrProvince/getDataAddr',
        description: '获取省市县三级数据',
        type: 'GET'
    },
    // 维修审批列表
    getRepairApprovalPageList: {
        url: url + 'claim/claimAuthRepairOrder/repairApproval',
        description: '维修审批列表',
        type: 'GET'
    },
    // 物流审批列表
    getLogisticsApprovalPageList: {
        url: url + 'claim/claimAuthRepairOrder/logisticsApproval',
        description: '物流审批列表',
        type: 'GET'
    },
    // 索赔物流列表
    getClaimLogisticsPageList: {
        url: url + 'claim/claimAuthRepairOrder/claimLogistics',
        description: '索赔物流列表',
        type: 'GET'
    },
    // 费用审批列表
    getCostApprovalPageList: {
        url: url + 'claim/claimAuthRepairOrder/costApproval',
        description: '费用审批列表',
        type: 'GET'
    },
    // 回访列表
    getFollowUpPageList: {
        url: url + 'claim/claimAuthRepairOrder/followUp',
        description: '回访列表',
        type: 'GET'
    },
    // 审批提交
    claimAuditAdd: {
        url: url + 'bsi/sysAudit/claimAudit/add',
        description: '审批提交',
        type: 'POST'
    },
	// 审批历史
	claimAuditHis: {
		url: url + 'bsi/sysAudit/claimAudit/his',
		description: '审批历史',
		type: 'POST'
	},
    // 发货
    claimAuthRepairOrderTrack: {
        url: url + 'claim/claimAuthRepairOrder/track',
        description: '发货',
        type: 'POST'
    },
    // 完成维修
    claimAuthRepairOrderFinish: {
        url: url + 'claim/claimAuthRepairOrder/finish',
        description: '完成维修',
        type: 'POST'
    },
    // 回访
    claimAuthRepairOrderFollow: {
        url: url + 'claim/claimAuthRepairOrder/follow',
        description: '回访',
        type: 'POST'
    },
    // 获取Advance地址
    getAdvanceData: {
        url: url + 'claim/claimRepairAdvance/getInfoByCode',
        description: '获取Advance地址',
        type: 'GET'
    },
    // 关联网络店绑定解绑接口
    sysAgentSure: {
        url: url + 'bsi/sysAgent/sure',
        description: '关联网络店绑定解绑接口',
        type: 'POST'
    },
	// 关联网络店绑定解绑接口 清除记录
	sysAgentSureDelTemp: {
		url: url + 'bsi/sysAgent/delTemp',
		description: '关联网络店绑定解绑接口 清除记录',
		type: 'POST'
	},
    // 归属关系绑定
    importAgentNet: {
        url: url + 'bsi/sysAgent/importAgentNet',
        description: '归属关系绑定',
        type: 'POST'
    },
	// 维修审批导出
	repairApprovalExport: {
		url: url + 'claim/claimAuthRepairOrder/repairApprovalExport',
		description: '维修审批导出',
		type: 'POST'
	},
	// 物流审批导出
	logisticsApprovalExport: {
		url: url + 'claim/claimAuthRepairOrder/logisticsApprovalExport',
		description: '物流审批导出',
		type: 'POST'
	},
	// 索赔物流导出
	claimLogisticsExport: {
		url: url + 'claim/claimAuthRepairOrder/claimLogisticsExport',
		description: '索赔物流导出',
		type: 'POST'
	},
	// 费用审批导出
	costApprovalExport: {
		url: url + 'claim/claimAuthRepairOrder/costApprovalExport',
		description: '费用审批导出',
		type: 'POST'
	},
	// 回访导出
	followUpExport: {
		url: url + 'claim/claimAuthRepairOrder/followUpExport',
		description: '回访导出',
		type: 'POST'
	},
	// 维修申请导出
	pageListExport: {
		url: url + 'claim/claimAuthRepairOrder/pageListExport',
		description: '维修申请导出',
		type: 'POST'
	},
	// 结算导出
	settleExport: {
		url: url + 'claim/claimRepairSettle/export',
		description: '结算导出',
		type: 'POST'
	},
	// 索赔管理-出库信息查询
	claimAuthRepairOrder: {
		url: url + 'claim/claimAuthRepairOrder/csmsSearch',
		description: '出库信息查询',
		type: 'GET'
	},
	// BBS分页查询
	bbsMainInfoList: {
		url: url + 'bbs/bbsMainInfo/pageList',
		description: 'bbs分页查询',
		type: 'POST'
	},
	// BBS新增
	bbsMainInfoAdd: {
		url: url + 'bbs/bbsMainInfo/add',
		description: 'bbs add',
		type: 'POST'
	},
	// BBS更新
	bbsMainInfoUpdate: {
		url: url + 'bbs/bbsMainInfo/update',
		description: 'bbs update',
		type: 'POST'
	},
	// BBS删除
	bbsMainInfoDel: {
		url: url + 'bbs/bbsMainInfo/del',
		description: 'bbs del',
		type: 'POST'
	},
	// BBS 回答
	bbsInfoReplyAdd: {
		url: url + 'bbs/bbsInfoReply/add',
		description: 'bbs 回答',
		type: 'POST'
	},
	// BBS详情
	bbsInfoReplyInfo: {
		url: url + 'bbs/bbsMainInfo/info',
		description: 'bbs 详情',
		type: 'POST'
	},
	// 维修单 主机 更新
	claimAuthMainPartsUpdate: {
		url: url + 'claim/claimAuthMainParts/update',
		description: '更新',
		type: 'POST'
	},
	// BBS代理商
	bbsListAgent: {
		url: url + 'bsi/sysAgent/listAgent',
		description: 'bbs 代理商',
		type: 'POST'
	},
	// BBS代理商-网络店
	bbsListStore: {
		url: url + 'bsi/sysAgent/listStore',
		description: 'bbs 代理商-网络店',
		type: 'POST'
	},
	// BBS 主题 加精
	bbsMainInfoGood: {
		url: url + 'bbs/bbsMainInfo/good',
		description: 'bbs good',
		type: 'POST'
	},
	// BBS 主题 警告
	bbsMainInfoWarn: {
		url: url + 'bbs/bbsMainInfo/warn',
		description: 'bbs warn',
		type: 'POST'
	},
	// BBS 评论 列表
	bbsInfoReplyList: {
		url: url + 'bbs/bbsInfoReply/pageList',
		description: 'bbs 评论 列表',
		type: 'POST'
	},
	// BBS 评论 警告
	bbsInfoReplyCommentGood: {
		url: url + 'bbs/bbsInfoReply/update',
		description: 'bbs 评论 警告',
		type: 'POST'
	},
	// BBS 评论 删除
	bbsInfoReplyCommentDel: {
		url: url + 'bbs/bbsInfoReply/del',
		description: 'bbs 评论 删除',
		type: 'POST'
	},
	// BBS 评论 采纳
	bbsInfoReplyCommentAdopt: {
		url: url + 'bbs/bbsInfoReply/adopt',
		description: 'bbs 评论 警告',
		type: 'POST'
	},
	// BBS 导出
	bbsExport: {
		url: url + 'bbs/bbsMainInfo/export',
		description: 'bbs 评论 警告',
		type: 'POST'
	},
}

export default api;
