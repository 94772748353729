import Vue from 'vue'
import VueRouter from 'vue-router'
import Layout from '@/layout'
Vue.use(VueRouter)

//获取原型对象上的push函数
const originalPush = VueRouter.prototype.push
//修改原型对象中的push方法
VueRouter.prototype.push = function push(location) {
   return originalPush.call(this, location).catch(err => err)
}

const routes = [
	// 登录
	{
		name: 'login',
		path: '/login',
		component: () => import('@/views/login'),
		meta: {}
	},
	// 注册
	{
		name: 'register',
		path: '/register',
		component: () => import('@/views/login/register'),
		meta: {}
	},
	// 忘记密码
	{
		name: 'forget',
		path: '/forget',
		component: () => import('@/views/login/forget'),
		meta: {}
	},
	// 找回密码
	{
		name: 'reset',
		path: '/reset',
		component: () => import('@/views/login/reset'),
		meta: {}
	},
	// 编辑用户信息
	{
		name: 'editUserInfo',
		path: '/editUserInfo',
		component: () => import('@/views/login/editUser'),
		meta: {}
	},
	// 编辑用户信息
	{
		name: 'editUserEmail',
		path: '/editUserEmail',
		component: () => import('@/views/login/editUserEmail'),
		meta: {}
	},
	// 首页
	{
		path: '/',
		component: Layout,
		redirect: '/home',
		name: 'home',
		meta: {},
		children: [
			// 首页
			{
				path: 'home',
				component: () => import('@/views/home/index.vue'),
				name: 'home',
				meta: {
					perm: 'home'
				}
			},
			// 公告栏
			{
				path: 'bulletin',
				component: () => import('@/views/bulletin/index.vue'),
				name: 'bulletin',
				meta: {
					perm: 'notice'
				}
			},
			// 公告栏新增修改
			{
				path: 'bulletin/addEdit',
				component: () => import('@/views/bulletin/addEdit.vue'),
				name: 'bulletinAddEdit',
				meta: {
					perm: 'notice'
				}
			},
			// 公告栏详情
			{
				path: 'bulletin/detail',
				component: () => import('@/views/bulletin/detail.vue'),
				name: 'bulletinDetail',
				meta: {
					perm: 'notice'
				}
			},
			// 图书馆
			{
				path: 'library',
				component: () => import('@/views/library/index.vue'),
				name: 'library',
				meta: {
					perm: 'library'
				}
			},
			// 图书馆新增修改
			{
				path: 'library/addEdit',
				component: () => import('@/views/library/addEdit.vue'),
				name: 'libraryAddEdit',
				meta: {
					perm: 'library'
				}
			},
			// 图书馆详情
			{
				path: 'library/detail',
				component: () => import('@/views/library/detail.vue'),
				name: 'libraryDetail',
				meta: {
					perm: 'library'
				}
			},
			// 通信箱
			{
				path: 'mailbox',
				component: () => import('@/views/mailbox/index.vue'),
				name: 'mailbox',
				meta: {
					perm: 'subject'
				}
			},
			// 通信箱新增修改
			{
				path: 'mailbox/addEdit',
				component: () => import('@/views/mailbox/addEdit.vue'),
				name: 'mailboxAddEdit',
				meta: {
					perm: 'subject'
				}
			},
			// 通信箱详情
			{
				path: 'mailbox/detail',
				component: () => import('@/views/mailbox/detail.vue'),
				name: 'mailboxDetail',
				meta: {
					perm: 'subject'
				}
			},
			// 考前学习
			{
				path: 'examLearn',
				component: () => import('@/views/trainingManagement/exam/learn.vue'),
				name: 'examLearn',
				meta: {
					perm: 'train:online'
				}
			},
			// 考前须知
			{
				path: 'examBefore',
				component: () => import('@/views/trainingManagement/exam/examBefore.vue'),
				name: 'examBefore',
				meta: {
					perm: 'train:online'
				}
			},
			// 考试进行中
			{
				path: 'exameIn',
				component: () => import('@/views/trainingManagement/exam/exameIn.vue'),
				name: 'exameIn',
				meta: {
					perm: 'train:online'
				}
			},
			// 考试结束
			{
				path: 'examAfter',
				component: () => import('@/views/trainingManagement/exam/examAfter.vue'),
				name: 'examAfter',
				meta: {
					perm: 'train:online'
				}
			},
			// 技术资料
			{
				path: 'technique',
				component: () => import('@/views/technique/index.vue'),
				name: 'technique',
				meta: {
					perm: 'home'
				}
			},
			// 技术资料详情
			{
				path: 'technique/detail',
				component: () => import('@/views/technique/detail.vue'),
				name: 'techniqueDetail',
				meta: {
					perm: 'home'
				}
			},
			// 视频分享
			{
				path: 'video',
				component: () => import('@/views/video/index.vue'),
				name: 'video',
				meta: {
					perm: 'home'
				}
			},
			// 视频新增
			{
				path: 'video/addEdit',
				component: () => import('@/views/video/addEdit.vue'),
				name: 'videoAddEdit',
				meta: {
					// perm: 'video:add'
					perm: 'home'
				}
			},
			// 视频详情
			{
				path: 'video/detail',
				component: () => import('@/views/video/detail.vue'),
				name: 'videoDetail',
				meta: {
					perm: 'video:detail'
				}
			},
			// 培训管理
			{
				path: 'trainingManagement',
				component: () => import('@/views/trainingManagement/index.vue'),
				name: 'trainingManagement',
				meta: {
					perm: 'train'
				},
				children: [
					// 培训日程表
					{
						path: 'schedule',
						component: () => import('@/views/trainingManagement/schedule/index.vue'),
						name: 'schedule',
						meta: {
							title: '培训日程表',
							perm: 'train:schedule'
						}
					},
					// 线上培训
					{
						path: 'onlinePublish',
						component: () => import('@/views/trainingManagement/trainOnline/index.vue'),
						name: 'onlinePublish',
						meta: {
							title: '远程培训发布',
							perm: 'train:online'
						}
					},
					{
						path: 'onlineExam',
						component: () => import('@/views/trainingManagement/trainOnline/exam.vue'),
						name: 'onlineExam',
						meta: {
							title: '远程考试',
							perm: 'train:online'
						}
					},
					{
						path: 'onlineResult',
						component: () => import('@/views/trainingManagement/trainOnline/result.vue'),
						name: 'onlineResult',
						meta: {
							title: '考试成绩',
							perm: 'train:online'
						}
					},
					// 线下培训
					{
						path: 'offlineAdd',
						component: () => import('@/views/trainingManagement/trainOffline/index.vue'),
						name: 'offlineAdd',
						meta: {
							title: '新增集合',
							perm: 'train:offline'
						}
					},
					{
						path: 'offlineSign',
						component: () => import('@/views/trainingManagement/trainOffline/sign.vue'),
						name: 'offlineSign',
						meta: {
							title: '集合报名',
							perm: 'train:offline'
						}
					},
					{
						path: 'signMember',
						component: () => import('@/views/trainingManagement/trainOffline/signMember.vue'),
						name: 'signMember',
						meta: {
							title: '集合报名',
							perm: 'train:offline'
						}
					},
					{
						path: 'offlineResult',
						component: () => import('@/views/trainingManagement/trainOffline/result.vue'),
						name: 'offlineResult',
						meta: {
							title: '集合成绩',
							perm: 'train:offline'
						}
					},
					{
						path: 'offlineAppend',
						component: () => import('@/views/trainingManagement/trainOffline/append.vue'),
						name: 'offlineAppend',
						meta: {
							title: '补登成绩',
							perm: 'train:offline'
						}
					},
					{
						path: 'offlineResultDetail',
						component: () => import('@/views/trainingManagement/trainOffline/resultDetail.vue'),
						name: 'offlineResultDetail',
						meta: {
							title: '集合成绩',
							perm: 'train:offline'
						}
					},
					// 课程管理
					{
						path: 'courseManagement',
						component: () => import('@/views/trainingManagement/questionManagement/index.vue'),
						name: 'courseManagement',
						meta: {
							title: '课程管理',
							perm: 'train:question'
						}
					},
					{
						path: 'questionManagement',
						component: () => import('@/views/trainingManagement/questionManagement/question.vue'),
						name: 'questionManagement',
						meta: {
							title: '试题管理',
							perm: 'train:question'
						}
					},
					{
						path: 'questionList',
						component: () => import('@/views/trainingManagement/questionManagement/questionList.vue'),
						name: 'questionList',
						meta: {
							title: '试题管理',
							perm: 'train:question'
						}
					},
					// 公司管理
					{
						path: 'companyManagement',
						component: () => import('@/views/trainingManagement/companyManagement/index.vue'),
						name: 'companyManagement',
						meta: {
							title: '公司管理',
							perm: 'train:company'
						}
					},
					// 学员管理
					{
						path: 'studentManagement',
						component: () => import('@/views/trainingManagement/studentManagement/index.vue'),
						name: 'studentManagement',
						meta: {
							title: '学员管理',
							perm: 'train:student'
						}
					},
					// 教师管理
					{
						path: 'teacherManagement',
						component: () => import('@/views/trainingManagement/teacherManagement/index.vue'),
						name: 'teacherManagement',
						meta: {
							title: '教师管理',
							perm: 'train:teacher'
						}
					},
					// 会场管理
					{
						path: 'roomManagement',
						component: () => import('@/views/trainingManagement/roomManagement/index.vue'),
						name: 'roomManagement',
						meta: {
							title: '会场管理',
							perm: 'train:room'
						}
					},
					// 培训履历
					{
						path: 'onlineHistory',
						component: () => import('@/views/trainingManagement/trainHistory/online.vue'),
						name: 'onlineHistory',
						meta: {
							title: '远程履历',
							perm: 'train:history'
						}
					},
					// 培训履历
					{
						path: 'onlineInvalidHistory',
						component: () => import('@/views/trainingManagement/trainHistory/onlineInvalid.vue'),
						name: 'onlineInvalidHistory',
						meta: {
							title: '远程履历-失效',
							perm: 'train:history'
						}
					},
					// 培训履历
					{
						path: 'offlineHistory',
						component: () => import('@/views/trainingManagement/trainHistory/offline.vue'),
						name: 'offlineHistory',
						meta: {
							title: '集合履历',
							perm: 'train:history'
						}
					},
					// 培训履历
					{
						path: 'offlineInvalidHistory',
						component: () => import('@/views/trainingManagement/trainHistory/offlineInvalid.vue'),
						name: 'offlineInvalidHistory',
						meta: {
							title: '集合履历-失效',
							perm: 'train:history'
						}
					}
				]
			},
			// 系统管理
			{
				path: 'systemManagement',
				component: () => import('@/views/systemManagement/index.vue'),
				name: 'systemManagement',
				meta: {
					title: '系统管理',
					perm: 'sys'
				},
				children: [
					// 用户管理
					{
						path: 'userManagement',
						component: () => import('@/views/systemManagement/userManagement/index.vue'),
						name: 'userManagement',
						meta: {
							title: '用户管理',
							perm: 'sys:user'
						}
					},
					// 角色管理
					{
						path: 'roleManagement',
						component: () => import('@/views/systemManagement/roleManagement/index.vue'),
						name: 'roleManagement',
						meta: {
							title: '角色管理',
							perm: 'sys:role'
						}
					},
					// 通讯组管理
					{
						path: 'departmentManagement',
						component: () => import('@/views/systemManagement/departmentManagement/index.vue'),
						name: 'departmentManagement',
						meta: {
							title: '通讯组管理',
							perm: 'sys:dept'
						}
					},
					// 通讯组管理
					{
						path: 'dealerGroup',
						component: () => import('@/views/systemManagement/dealerGroup/index.vue'),
						name: 'dealerGroup',
						meta: {
							title: '经销商分组',
							perm: 'sys:dept'
						}
					},
					// 数据类别管理
					{
						path: 'dataManagement',
						component: () => import('@/views/systemManagement/dataManagement/index.vue'),
						name: 'dataManagement',
						meta: {
							title: '数据类别管理',
							perm: 'sys:data'
						}
					},
					// 机种机型管理
					{
						path: 'modelManagement',
						component: () => import('@/views/systemManagement/modelManagement/index.vue'),
						name: 'modelManagement',
						meta: {
							title: '机种机型管理',
							perm: 'sys:deviceModel'
						}
					},
					// 机型名称维护
					{
						path: 'modelName',
						component: () => import('@/views/systemManagement/modelName/index.vue'),
						name: 'modelName',
						meta: {
							title: '机型名称维护',
							perm: 'sys:device'
						}
					},
					// 签约类型管理
					{
						path: 'contractManagement',
						component: () => import('@/views/systemManagement/contractManagement/index.vue'),
						name: 'contractManagement',
						meta: {
							title: '签约类型管理',
							perm: 'sys:contract'
						}
					},
					// 省市区域管理
					{
						path: 'cityManagement',
						component: () => import('@/views/systemManagement/cityManagement/index.vue'),
						name: 'cityManagement',
						meta: {
							title: '省市区域管理',
							perm: 'sys:area'
						}
					},
					// 经销商管理
					{
						path: 'dealerManagement',
						component: () => import('@/views/systemManagement/dealerManagement/index.vue'),
						name: 'dealerManagement',
						meta: {
							title: '经销商管理',
							perm: 'sys:agent'
						}
					},
					// 培训中心管理
					{
						path: 'trainingManagement',
						component: () => import('@/views/systemManagement/trainingManagement/index.vue'),
						name: 'trainingManagement',
						meta: {
							title: '培训中心管理',
							perm: 'sys:tracen'
						}
					},
					// 参数配置
					{
						path: 'parameterConfig',
						component: () => import('@/views/systemManagement/parameterConfig/index.vue'),
						name: 'parameterConfig',
						meta: {
							title: '参数设置',
							perm: 'sys:config'
						}
					}
				]
			},
            // 索赔管理
            {
                path: 'claimantManagement',
                component: () => import('@/views/claimantManagement/index.vue'),
                name: 'claimantManagement',
                meta: {
                    title: '索赔管理',
                    perm: 'home:repair'
                },
                children: [
                    // 维修申请
                    {
                        path: 'repairRequest',
                        component: () => import('@/views/claimantManagement/repairRequest/index.vue'),
                        name: 'repairRequest',
                        meta: {
                            title: '维修申请',
                            perm: 'home:repair:apply'
                        }
                    },
                    // 维修申请-新增
                    {
                        path: 'repairRequestAddEdit',
                        component: () => import('@/views/claimantManagement/repairRequest/edit.vue'),
                        name: 'repairRequestAddEdit',
                        meta: {
                            title: '维修申请-新增',
                            perm: 'home:repair:apply'
                        }
                    },
                    // 维修申请-详情
                    {
                        path: 'repairRequestDetail',
                        component: () => import('@/views/claimantManagement/repairRequest/detail.vue'),
                        name: 'repairRequestDetail',
                        meta: {
                            title: '维修申请-详情',
                            perm: 'home:repair'
                        }
                    },
                    // 维修机型
                    {
                        path: 'repairModel',
                        component: () => import('@/views/claimantManagement/repairModel/index.vue'),
                        name: 'repairModel',
                        meta: {
                            title: '维修机型',
                            perm: 'home:repair:model'
                        }
                    },
                    // 维修审批
                    {
                        path: 'repairApproval',
                        component: () => import('@/views/claimantManagement/repairApproval/index.vue'),
                        name: 'repairApproval',
                        meta: {
                            title: '维修审批',
                            perm: 'home:repair:approval'
                        }
                    },
                    // 物流审批
                    {
                        path: 'logisticsApproval',
                        component: () => import('@/views/claimantManagement/logisticsApproval/index.vue'),
                        name: 'logisticsApproval',
                        meta: {
                            title: '物流审批',
                            perm: 'home:repair:logisticsApproval'
                        }
                    },
                    // 索赔物流
                    {
                        path: 'claimantLogistics',
                        component: () => import('@/views/claimantManagement/claimantLogistics/index.vue'),
                        name: 'claimantLogistics',
                        meta: {
                            title: '索赔物流',
                            perm: 'home:repair:claimsLogistics'
                        }
                    },
                    // 费用审批
                    {
                        path: 'expenseApproval',
                        component: () => import('@/views/claimantManagement/expenseApproval/index.vue'),
                        name: 'expenseApproval',
                        meta: {
                            title: '费用审批',
                            perm: 'home:repair:expenseApproval'
                        }
                    },
                    // 回访
                    {
                        path: 'followUp',
                        component: () => import('@/views/claimantManagement/followUp/index.vue'),
                        name: 'followUp',
                        meta: {
                            title: '回访',
                            perm: 'home:repair:returnVisit'
                        }
                    },
					// 延保记录
					{
						path: 'extendedWarrantyRecord',
						component: () => import('@/views/claimantManagement/extendedWarrantyRecord/index.vue'),
						name: 'extendedWarrantyRecord',
						meta: {
							title: '延保记录',
							perm: 'home:repair:extendedWarrantyRecord'
						}
					},
					// 送货地址
					{
						path: 'repairAdvance',
						component: () => import('@/views/claimantManagement/repairAdvance/index.vue'),
						name: 'repairAdvance',
						meta: {
							title: '送货地址',
							perm: 'home:repair:shippingAddress'
						}
					},
					// 结算信息
					{
						path: 'repairSettle',
						component: () => import('@/views/claimantManagement/repairSettle/index.vue'),
						name: 'repairSettle',
						meta: {
							title: '结算信息',
							perm: 'home:repair:settlement'
						}
					},
					// 已换机机号
					{
						path: 'repairExchange',
						component: () => import('@/views/claimantManagement/repairExchange/index.vue'),
						name: 'repairExchange',
						meta: {
							title: '已换机机号',
							perm: 'home:repair:replacedDeviceNumber'
						}
					},
                ]
            },
			// BBS
			{
				path: 'bbs',
				component: () => import('@/views/bbs/index.vue'),
				name: 'bbs',
				meta: {
					title: '技术交流',
					perm: 'home:bbs'
				},
				children: [
					// bbs 问题管理
					{
						path: 'contentManagement',
						component: () => import('@/views/bbs/contentManagement/index.vue'),
						name: 'contentManagement',
						meta: {
							perm: 'home:bbs:content'
						},},
					// bbs 案例管理
					{
						path: 'caseManagement',
						component: () => import('@/views/bbs/caseManagement/index.vue'),
						name: 'caseManagement',
						meta: {
							perm: 'home:bbs:case'
						},
					},
					// bbs 新增修改
					{
						path: 'bbsAddEdit',
						component: () => import('@/views/bbs/addEdit.vue'),
						name: 'bbsAddEdit',
						meta: {
							perm: 'home:bbs'
						}
					},
					// bbs 详情
					{
						path: 'bbsDetail',
						component: () => import('@/views/bbs/detail.vue'),
						name: 'bbsDetail',
						meta: {
							perm: 'home:bbs'
						}
					},
				]
			}
		]
	},
]

const router = new VueRouter({
	// 打包
	base: '/',
	routes: routes
})

export default router

router.beforeEach((to, from, next) => {
	// 权限
	if(window.sessionStorage.getItem('perms')) {
		let perms = JSON.parse(window.sessionStorage.getItem('perms'));
		if(!perms.includes(to.meta.perm)) {
			if(to.name == 'editUserEmail'){
				next()
			}
			else if(to.name == 'editUserInfo'){
				// 没有邮箱禁止跳转
				if(JSON.parse(window.sessionStorage.userInfo).email == '') {
					next({
						path: '/editUserEmail'
					});
				}else {
					next();
				}
			}
			else if(to.name == 'login'){
				next();
			}
			else {
				return
			}
		}else {
			// token
			if(to.name == 'login') {
				next();
			}
			else if(window.sessionStorage.getItem('token')){
				// 没有邮箱禁止跳转
				if(JSON.parse(window.sessionStorage.userInfo).email == '') {
					next({
						path: '/editUserEmail'
					});
				}else {
					next();
				}
			}
			else {
				next({
					path: '/login'
				});
			}
		}
	}
	// 无权限
	else {
		// token
		if(to.name == 'login' || to.name == 'register' || to.name == 'forget' || to.name == 'reset') {
			next();
		}
		else if(window.sessionStorage.getItem('token')){
			next();
		}
		else {
			if(to.name == 'mailboxDetail') {
				window.sessionStorage.setItem('mailId',to.query.id);
			}
			if(to.name == 'videoDetail') {
				window.sessionStorage.setItem('videoId',to.query.id);
			}
			if (to.name == 'repairRequestDetail') {
				window.sessionStorage.setItem('repairDetail',JSON.stringify(to.query));
			}
			next({
				path: '/login',
				redirect: to.fullPath
			});
		}
	}
})
